/**
 * Created by Henry Huang on 2018/7/10.
 */
import { CommonResponse, Gallery, Trip, News, Price, ICredential, IContactMessage } from './dto.types';

import { Booking as IBooking } from '../BookingForm'

async function request<D>(
  url: string,
  config: RequestInit,
): Promise<CommonResponse<D>> {
  const response: Response = await fetch(url, config);
  return await response.json() as CommonResponse<D>;
}

export const sendEmail = async (booking: IBooking): Promise<boolean> => {
  const url = `/api/trips/${booking.tripId}/booking`
  const response: CommonResponse<boolean> = await request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(booking)
  })

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data;
}


export const sendContactForm = async (message: IContactMessage): Promise<boolean> => {
  const url = `/api/others/contactForm`
  const response: CommonResponse<boolean> = await request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(message)
  })

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data;
}

export const loginUser = async (credentials: ICredential): Promise<boolean> => {
  const url = '/api/auth/login'
  const response: CommonResponse<boolean> = await request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data;
}

export const checkToken = async (token: string): Promise<boolean> => {
  const url = '/api/auth/check';
  const response: CommonResponse<boolean> = await request(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: token.toString()
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return true
}


////
//      TRIP
////

export const createTrip = async (formData: FormData, token: string): Promise<Trip> => {
  const url = `/api/trips`;
  const response: CommonResponse<Trip> = await request(url, {
    method: 'post',
    headers: {
      'Authorization': "Bearer " + token,
    },
    body: formData
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }

  return response.data;
}

export const addImagesToTrip = async (id: string, formData: FormData, token: string): Promise<Trip> => {
  const url = `/api/trips/${id}/images`;
  const response: CommonResponse<Trip> = await request(url, {
    method: 'put',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    body: formData
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data as Trip;
}

export const deleteImageFromTrip = async (id: string, tripId: number, token: string): Promise<boolean> => {
  const url = `/api/trips/${tripId}/images/${id}`
  const response: CommonResponse<boolean> = await request(url, {
    method: 'DELETE',
    headers: {
      "Authorization": "Bearer " + token,
    }
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }

  return true
}

export const deleteTrip = async (id: string, token: string): Promise<boolean> => {
  const url = `/api/trips/${id}`;
  const response: CommonResponse<boolean> = await request(url, {
    method: 'delete',
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return true;
}


export const modifyTrip = async (id: string, formData: FormData, token: string): Promise<boolean> => {
  const url = `/api/trips/${id}`;
  const response: CommonResponse<boolean> = await request(url, {
    method: 'put',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    body: formData
  })

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data;
}

export const fetchTrips = async (): Promise<Trip[]> => {
  const url = '/api/trips/';
  const response: CommonResponse<Trip[]> = await request(url, {
    method: 'get'
  })

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data;
}

export const fetchTripById = async (id: string): Promise<Trip> => {
  const url = `/api/trips/${id}`;
  const response: CommonResponse<Trip> = await request(url, {
    method: 'get'
  });
  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }

  return response.data;
}


export const addPricesToTrip = async (id: string, prices: Price[], token: string): Promise<boolean> => {
  const url = `/api/trips/${id}/up_prices`;
  const response: CommonResponse<boolean> = await request(url, {
    method: 'post',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': "application/json"
    },
    body: JSON.stringify(prices)
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data;
}

export const deletePriceFromTrip = async (priceId: string, tripId: string, token: string): Promise<boolean> => {
  const url = `/api/trips/${tripId}/up_prices/${priceId}`;
  const response: CommonResponse<boolean> = await request(url, {
    method: 'delete',
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }

  return true
}




export const setTripTitleImage = async (imageId: string, tripId: string, token: string): Promise<boolean> => {
  const url = `/api/trips/${tripId}/images/${imageId}/main`;

  const response: CommonResponse<boolean> = await request(url, {
    method: 'put',
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }

  return true
}




////
//    GALLERY
///

export const fetchGalleries = async (): Promise<Gallery[]> => {
  const url = '/api/galleries/';
  const response: CommonResponse<Gallery[]> = await request(url, {
    method: 'get'
  });
  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data;
}

export const fetchGalleryById = async (id: string): Promise<Gallery> => {
  const url = `/api/galleries/${id}`;
  const response: CommonResponse<Gallery> = await request(url, {
    method: 'get'
  });
  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data;
}

export const fetchGalleryByTripId = async (tripId: string): Promise<Gallery> => {
  const url = `/api/galleries/trip/${tripId}`;
  const response: CommonResponse<Gallery> = await request(url, {
    method: 'get'
  });
  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data;
}

export const createGallery = async (formData: FormData, tripId: number, token: string): Promise<Gallery> => {
  const url = `/api/galleries/trip/${tripId}`;
  const response: CommonResponse<Gallery> = await request(url, {
    method: 'post',
    headers: {
      'Authorization': "Bearer " + token,
    },
    body: formData
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }

  return response.data;
}

export const updateGallery = async (id: string, formData: FormData, token: string): Promise<boolean> => {
  const url = `/api/galleries/${id}`;
  const response: CommonResponse<boolean> = await request(url, {
    method: 'put',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    body: formData
  })

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return true;
}

export const addImagesToGallery = async (id: string, formData: FormData, token: string): Promise<boolean> => {
  const url = `/api/galleries/${id}/images`;
  const response: CommonResponse<boolean> = await request(url, {
    method: 'put',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    body: formData
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data;
}

export const deleteGallery = async (id: string, token: string): Promise<boolean> => {
  const url = `/api/galleries/${id}`;
  const response: CommonResponse<boolean> = await request(url, {
    method: 'delete',
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return true;
}


export const deleteImageFromGallery = async (id: string, gallery: Gallery, token: string): Promise<boolean> => {
  const url = `/api/galleries/${gallery.id}/images/${id}`
  const response: CommonResponse<boolean> = await request(url, {
    method: 'DELETE',
    headers: {
      "Authorization": "Bearer " + token,
    }
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }

  return true
}



export const setGalleryTitleImage = async (imageId: string, galleryId: string, token: string): Promise<boolean> => {
  const url = `/api/galleries/${galleryId}/images/${imageId}/main`;

  const response: CommonResponse<boolean> = await request(url, {
    method: 'put',
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }

  return true
}





////
//      NEWS
////


export const fetchNews = async (): Promise<News[]> => {
  const url = '/api/newses/';
  const response: CommonResponse<Trip[]> = await request(url, {
    method: 'get'
  });
  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data;
}

export const fetchNewsById = async (id: string): Promise<News> => {
  const url = `/api/newses/${id}`;
  const response: CommonResponse<News> = await request(url, {
    method: 'get'
  });
  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data;
}

export const createNews = async (formData: FormData, token: string): Promise<News> => {
  const url = `/api/newses`;
  const response: CommonResponse<News> = await request(url, {
    method: 'POST',
    headers: {
      'Authorization': "Bearer " + token,
    },
    body: formData
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }

  return response.data;
}


export const deleteNews = async (id: string, token: string): Promise<boolean> => {
  const url = `/api/newses/${id}`;
  const response: CommonResponse<boolean> = await request(url, {
    method: 'delete',
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data;
}

export const deleteNewsAttachment = async (newsId: number, id: number, token: string): Promise<boolean> => {
  const url = `/api/newses/${newsId}/attachments/${id}`
  const response: CommonResponse<boolean> = await request(url, {
    method: 'DELETE',
    headers: {
      "Authorization": "Bearer " + token,
    }
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return true;
}

export const updateNews = async (id: string, formData: FormData, token: string): Promise<boolean> => {
  const url = `/api/newses/${id}`;
  const response: CommonResponse<boolean> = await request(url, {
    method: 'put',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    body: formData
  })

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data;
}

export const addAttachmentsToNews = async (id: string, formData: FormData, token: string): Promise<boolean> => {
  const url = `/api/newses/${id}/attachments`;
  const response: CommonResponse<boolean> = await request(url, {
    method: 'post',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    body: formData
  });

  if (!response.ok) {
    throw new Error(response.errors ? response.errors.join(', ') : '');
  }
  return response.data;
}