import "draft-js/dist/Draft.css";
import "react-datepicker/dist/react-datepicker.css";
import { checkToken as authenticate } from '../../service/api.service'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { MouseEvent, useState, useEffect, useReducer } from 'react'
import { parseTrip, getNewTrip, getDaysLength } from '../../service/trip.service'
import { Style } from "../Style"
import { TagsInput } from "react-tag-input-component";
import { toast } from 'react-toastify';
import { Trip, Price, Image } from '../../service/dto.types';
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useSession } from "./Container";
import * as api from '../../service/api.service'
import AnkEditor from "../ui/Editor"
import CurrencyInput from 'react-currency-input-field';
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import InputField from '../../ui/InputField'
import Button from '../../ui/buttons/Button'
import TripImage from './TripImage'

registerLocale("de", de);

const formatPrice = (number: number) => {
  return number.toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })
}

const ACTIONS = {
  INIT: 'init',
  SET_TRIP: 'set-trip',
  SET_IS_NEW_TRIP: 'set-is-new-trip',
  SET_TAGS: 'set-tags',
  ADD_IMAGE_TO_TRIP: 'add-image-to-trip',
  DELETE_IMAGE_FROM_TRIP: 'delete-image-from-trip',
  ADD_PRICE: 'add-price',
  REMOVE_PRICE: 'remove-price',
  SET_SELECTED_TAGS: 'set-selected-tags',
  INIT_DETAILS_EDITOR_STATE: 'init-details-editor-state',
  INIT_SERVICES_EDITOR_STATE: 'init-services-editor-state',
  SET_DETAILS_EDITOR_STATE: 'set-details-editor-state',
  SET_SERVICES_EDITOR_STATE: 'set-services-editor-state',
  ADD_IMAGE_UPLOADING: 'add-image-loading',
  DELETE_IMAGE_UPLOADING: 'delete-image-loading',
}

const TRIP_TYPE = {
  DAY: 'day',
  MULTI: 'multi',
}

interface IState {
  trip: Trip,
  isNewTrip: boolean,
  detailsEditorState: EditorState,
  servicesEditorState: EditorState,
  selectedTags: string[],
  imagesUploading: any[],
}

interface IAction {
  type: string,
  payload?: any,
}

const initialState: IState = {
  trip: getNewTrip(),
  isNewTrip: true,
  detailsEditorState: EditorState.createEmpty(),
  servicesEditorState: EditorState.createEmpty(),
  selectedTags: [""],
  imagesUploading: [],
}

const NewTrip = () => {
  const navigate = useNavigate();
  const { token, logout } = useSession();

  const reducer = (state: IState, action: IAction): IState => {
    switch (action.type) {
      case ACTIONS.INIT:
        return initialState
      case ACTIONS.SET_TRIP:
        if (!action.payload?.trip) return state
        return { ...state, trip: action.payload.trip }
      case ACTIONS.SET_IS_NEW_TRIP:
        if (action.payload?.isNewTrip === undefined) return state
        return { ...state, isNewTrip: action.payload.isNewTrip }
      case ACTIONS.ADD_IMAGE_TO_TRIP:
        if (!action.payload?.image) return state
        if (!state.trip.images) state.trip.images = [] as Image[]
        return { ...state, trip: { ...state.trip, images: [...state.trip.images, action.payload.image] } }
      case ACTIONS.DELETE_IMAGE_FROM_TRIP:
        console.log("DELETE_IMAGE_FROM_TRIP action.payload.idx", action.payload.idx)
        if (!state.trip.images || action.payload.idx === undefined) return state
        return { ...state, trip: { ...state.trip, images: state.trip.images.filter((i) => i.id !== action.payload.idx) } }
      case ACTIONS.ADD_PRICE:
        if (!action.payload?.price) return state
        if (!state.trip.prices) state.trip.prices = [] as Price[]
        return { ...state, trip: { ...state.trip, prices: [...state.trip.prices, action.payload.price] } }
      case ACTIONS.REMOVE_PRICE:
        if (!state.trip.prices || action.payload.idx === undefined) return state
        return { ...state, trip: { ...state.trip, prices: state.trip.prices.filter((_, i) => i !== action.payload.idx) } }
      case ACTIONS.SET_SELECTED_TAGS:
        if (!action.payload?.tags) return state
        return { ...state, selectedTags: action.payload.tags }
      case ACTIONS.INIT_DETAILS_EDITOR_STATE:
        if (!state.trip.detail) return state

        try {
          let editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(state.trip.detail)))
          return { ...state, detailsEditorState: editorState }
        } catch (err) {
          return { ...state }
        }
      case ACTIONS.INIT_SERVICES_EDITOR_STATE:
        if (!state.trip.services) return state

        try {
          let editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(state.trip.services)))
          return { ...state, servicesEditorState: editorState }
        } catch (err) {
          return { ...state }
        }
      case ACTIONS.SET_DETAILS_EDITOR_STATE:
        if (!action.payload.editorState) return state
        return { ...state, detailsEditorState: action.payload.editorState }
      case ACTIONS.SET_SERVICES_EDITOR_STATE:
        if (!action.payload.editorState) return state
        return { ...state, servicesEditorState: action.payload.editorState }
      case ACTIONS.ADD_IMAGE_UPLOADING:
        if (!action.payload?.image) return state
        return { ...state, imagesUploading: [...state.imagesUploading, action.payload.image] }
      case ACTIONS.DELETE_IMAGE_UPLOADING:
        if (!action.payload?.idx) return state
        return { ...state, imagesUploading: state.imagesUploading.filter(i => i.id !== action.payload.idx) }
      default:
        console.log("Unknown action: " + action.type)
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  // State
  const [images, setImages] = useState<FileList>()
  const imagesList = images ? [...images] : [];

  const [priceCat, setPriceCat] = useState({
    category: "Standard",
    price: "0.00"
  })
  const [showLoader, setShowLoader] = useState(false)
  const [showUploadLoader, setShowUploadLoader] = useState(false)

  // Form Validation
  const form = useForm();
  const { id: tripId } = useParams()

  useEffect(() => {
    if (tripId) {
      // Fetch existing trip data
      api.fetchTripById(tripId).then(trip => {
        if (!trip) { throw Error() }

        // Parse trip and save state
        const t = parseTrip(trip)
        form.reset()

        dispatch({ type: ACTIONS.SET_TRIP, payload: { trip: t } })
        dispatch({ type: ACTIONS.SET_SELECTED_TAGS, payload: { tags: t.tags } })
        dispatch({ type: ACTIONS.SET_IS_NEW_TRIP, payload: { isNewTrip: false } })

        // Editor state
        dispatch({ type: ACTIONS.INIT_DETAILS_EDITOR_STATE })
        dispatch({ type: ACTIONS.INIT_SERVICES_EDITOR_STATE })
      })
        .catch(() => {
          navigate("/admin/trips");
          toast.error("Reise konnte nicht gefunden werden!")
        })
    } else {
      // Initialize new trip
      dispatch({ type: ACTIONS.INIT })
    }
  }, [tripId, navigate, form])

  // Handle field change
  const handleFieldChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    dispatch({ type: ACTIONS.SET_TRIP, payload: { trip: { ...state.trip, [name]: value } } })

    form.clearErrors()
  }

  const handleDatesChanged = (date1: Date, date2: Date): void => {
    date1.setHours(2)
    date2.setHours(23)

    // Update trip date state
    dispatch({ type: ACTIONS.SET_TRIP, payload: { trip: { ...state.trip, date_begin: date1, date_end: date2 } } })

    // Update tags state
    let tags = state.trip.tags || []
    let tripType = getTripType(getDaysLength({ date_begin: date1, date_end: date2 }))

    if (tripType === TRIP_TYPE.MULTI) {
      // Is multi day trip
      if (!tags.includes("Ferienreise")) tags.push("Ferienreise")
      return dispatch({ type: ACTIONS.SET_SELECTED_TAGS, payload: { tags: tags.filter(t => t !== "Tagesfahrt") } })
    } else {
      if (!tags.includes("Tagesfahrt")) tags.push("Tagesfahrt")
      return dispatch({ type: ACTIONS.SET_SELECTED_TAGS, payload: { tags: tags.filter(t => t !== "Ferienreise") } })
    }
  }

  const getTripType = (days: number): string => {
    return days > 1 ? TRIP_TYPE.MULTI : TRIP_TYPE.DAY
  }

  const handleDateBeginChange = (date_begin: Date): void => {
    if (state.trip.date_end) {
      // if end date is before start date, update end date
      if (date_begin > state.trip.date_end) {
        form.setValue("date_end", date_begin) // update end date
        return handleDatesChanged(date_begin, date_begin)
      } else {
        return handleDatesChanged(date_begin, state.trip.date_end)
      }
    }

    dispatch({ type: ACTIONS.SET_TRIP, payload: { trip: { ...state.trip, date_begin: date_begin } } })
  }

  const handleDateEndChange = (date_end: Date): void => {
    // if start date is before end date, update start date
    if (state.trip.date_begin) {
      if (date_end < state.trip.date_begin) {
        form.setValue("date_begin", date_end) // update begin date
        return handleDatesChanged(date_end, date_end)
      } else {
        return handleDatesChanged(state.trip.date_begin, date_end)
      }
    }

    dispatch({ type: ACTIONS.SET_TRIP, payload: { trip: { ...state.trip, date_end: date_end } } })
  }

  // Add prices to trip and return promise
  const addPrices = (tripId: string): Promise<boolean> => {
    if (!state.trip.prices || !tripId) throw Error()
    if (state.trip.prices.length === 0) return Promise.resolve(true)

    return api.addPricesToTrip(tripId.toString(), state.trip.prices.filter((p) => p.id === undefined), token)
  }

  // Add images to trip and return promise
  const addImagesToTrip = (tripId: string): Promise<Image[] | undefined>[] => {
    if (!imagesList || !tripId) throw Error()

    if (imagesList.length > 0) {
      const imgs = imagesList.map((file) => {
        return addImageToTrip(tripId, file)
      });

      return imgs
    }

    // Images empty
    return [Promise.resolve([])]
  }

  const addImageToTrip = (tripId: string, image: File): Promise<Image[] | undefined> => {
    const img = {
      id: Math.floor(Math.random() * 1000),
      isLoading: true,
    }

    dispatch({ type: ACTIONS.ADD_IMAGE_UPLOADING, payload: { image: img } })

    return new Promise((resolve, reject) => {

      if (!image) reject(Error())

      const formData = new FormData();
      formData.append("images", image);

      api.addImagesToTrip(tripId.toString(), formData, token).then((trip) => {
        dispatch({ type: ACTIONS.DELETE_IMAGE_UPLOADING, payload: { idx: img.id } })

        // set trip images
        dispatch({ type: ACTIONS.SET_TRIP, payload: { trip: { ...state.trip, images: trip.images } }})

        resolve(trip.images)
      }
      ).catch((err) => {
        reject(err)
      })
    })
  }

  const handleUploadImages = () => {
    if (tripId === undefined) return toast.error("Reise konnte nicht gefunden werden!")
    if (!imagesList || imagesList.length === 0) return

    setShowUploadLoader(true)

    Promise.all(addImagesToTrip(tripId.toString())).then((returnedImageLists) => {
      // clear images list
      (document.getElementById("images") as HTMLInputElement).value = ""
      setImages(undefined)

      // concat returned image lists and remove duplicates
      const list = [...returnedImageLists.flat()].filter((value, index, self) =>
        index === self.findIndex((t) => (
          t?.id === value?.id
        )))

      dispatch({ type: ACTIONS.SET_TRIP, payload: { trip: { ...state.trip, images: list } } })

      setShowUploadLoader(false)
      toast.success("Bilder wurden erfolgreich hochgeladen.")
    })
      .catch(() => {
        setShowUploadLoader(false)
        toast.error("Es trat ein Problem beim Hochladen der Bilder auf.")
      })
  }

  // Handle submit of trip
  const onSubmit = async () => {
    // Date validation
    if (!state.trip.date_begin) { return form.setError("date_begin", { message: "Bitte geben Sie ein Datum ein!" }) }
    if (!state.trip.date_end) { return form.setError("date_end", { message: "Bitte geben Sie ein Datum ein!" }) }
    if (state.trip.date_end < state.trip.date_begin) {
      form.setError("date_begin", { message: "Der Reisebeginn kann nicht nach dem Reiseende sein!" })
      form.setError("date_end", { message: "Das Reiseende kann nicht vor dem Reisebeginn sein!" })
      return
    }
    // Price validation
    if (!state.trip.prices || state.trip.prices.length === 0) {
      return form.setError("price_category", { message: "Bitte geben Sie eine Preiskategorie ein!" })
    }

    setShowLoader(true)

    // Create the form data
    const formData = new FormData();

    const trip = JSON.stringify({
      id: state.trip.id,
      title: state.trip.title,
      description: state.trip.description,
      date_begin: state.trip.date_begin,
      date_end: state.trip.date_end,
      services: state.trip.services,
      detail: state.trip.detail,
      price: state.trip.prices.find(p => p.category === "Standard")?.price ?? 0,
      tags: state.selectedTags && state.selectedTags.length > 0 ? state.selectedTags.join(",") : "",
    })
    formData.append('trip', trip);
    
    if (!state.isNewTrip && tripId) {
      // Modify existing trip
      authenticate(token).then(() => {
        api.modifyTrip(tripId.toString(), formData, token).then(() => {

          Promise.all([addPrices(tripId.toString()), ...addImagesToTrip(tripId.toString())]).then(() => {
            toast.success("Reise wurde erfolgreich bearbeitet.")
            navigate("/admin/trips")
          })
            .catch(() => {
              toast.error("Es trat ein Problem beim Hinzufügen von Preisen oder Bildern zu der Reise auf.")
              setShowLoader(false)
            })
        })
          .catch((err) => {
            toast.error("Reise konnte nicht bearbeitet werden")
            setShowLoader(false)
          })
      })
        .catch(() => {
          logout()
          toast("Ihre Session ist abgelaufen! Bitte loggen Sie sich erneut ein.")
          setShowLoader(false)
        })
    }
    else {
      authenticate(token).then(() => {
        api.createTrip(formData, token).then((trip) => {
          if (!trip || !trip.id) throw Error()

          // Add prices to new trip via API call
          Promise.all([addPrices(trip.id.toString())]).then(() => {

            const promises: any = imagesList.map((file) => {
              return trip.id ? addImageToTrip(trip.id.toString(), file) : Promise.resolve(true)
            });

            Promise.all(promises).then(() => {
              toast.success("Reise wurde erfolgreich erstellt.")
              navigate("/admin/trips")
            })
              .catch(() => {
                toast.error("Es trat ein Problem beim Hinzufügen Bildern zu der Reise auf.")
                setShowLoader(false)
              })
          }).catch(() => {
            toast.error("Es trat ein Problem beim Hinzufügen von Preisen zu der Reise auf.")
            setShowLoader(false)
          })
        })
          .catch(() => {
            toast.error("Reise konnte nicht erstellt werden")
            setShowLoader(false)
          })
      })
        .catch(() => {
          logout()
          toast("Ihre Session ist abgelaufen! Bitte loggen Sie sich erneut ein.")
          setShowLoader(false)
        })
    }
  }

  const handleAddPrice = () => {
    // Clear the error message of the price_category input field
    form.clearErrors("price_category")

    // Check if the price category already exists
    if (state.trip.prices?.some(cat => cat.category === priceCat.category)) {
      return form.setError("price_category", { type: "manual", message: "Preis existiert bereits" })
    }
    
    dispatch({
      type: ACTIONS.ADD_PRICE, payload: {
        price: {
          category: priceCat.category,
          price: parseFloat(priceCat.price)
        }
      }
    })
  }

  const handleRemovePrice = (event: MouseEvent) => {
    const target = (event.currentTarget as HTMLInputElement)
    const idx = parseInt(target.getAttribute("data-idx") ?? "-1")
    const priceToDelete = state.trip.prices?.find((_, i) => i === idx)

    if (state.isNewTrip) return dispatch({ type: ACTIONS.REMOVE_PRICE, payload: { idx } })

    if (!state.trip.id || !priceToDelete || !priceToDelete.id) return toast.error("Fehler beim Löschen der Preiskategorie.")
    //if (state.trip.prices && state.trip.prices.length === 1) return toast.warning("Sie können die einzige gespeicherte Preiskategorie nicht löschen!")

    deletePriceFromTrip(priceToDelete.id.toString(), state.trip.id?.toString()).then(() => {
      dispatch({ type: ACTIONS.REMOVE_PRICE, payload: { idx } })
    })
  }

  const handleSetAsTitle = async (event: MouseEvent) => {
    const target = (event.currentTarget as HTMLInputElement)
    const id = target.getAttribute("data-id")

    if (!id) { return toast.error("Bild als Titelbild festlegen fehlgeschlagen. (error message: missing id.)") }
    if (!tripId) { return toast.error("Bild als Titelbild festlegen fehlgeschlagen. (error message: no tripId vorhanden.)") }

    authenticate(token).then(() => {
      api.setTripTitleImage(id.toString(), tripId.toString(), token).then(() => {
        dispatch({
          type: ACTIONS.SET_TRIP, payload: {
            trip: { ...state.trip, images: state.trip.images?.map(i => { return { ...i, is_main: i.id.toString() === id } }) }
          }
        })

        //toast.success("Bild erfolgreich als Titelbild festgelegt.")
      })
        .catch(() => {
          toast.error("Bild als Titelbild festlegen fehlgeschlagen.")
        })
    })
  }

  const handleDeleteImage = async (event: MouseEvent) => {
    // Confirm delete
    if (window.confirm("Sind Sie sicher?")) {
      const target = (event.currentTarget as HTMLInputElement)
      const id = target.getAttribute("data-id")

      authenticate(token).then(() => {
        if (!id || !state.trip.id) { return toast.error("Fehler beim Löschen des Bildes.") }
        api.deleteImageFromTrip(id, state.trip.id, token).then(() => {
          toast.success("Bild erfolgreich gelöscht.")
          dispatch({ type: ACTIONS.DELETE_IMAGE_FROM_TRIP, payload: { idx: parseInt(id) } })
        })
          .catch(() => {
            toast.error("Fehler beim Löschen des Bildes.")
          })
      })
        .catch(() => {
          toast.error("Ihre Session ist abgelaufen! Bitte loggen Sie sich erneut ein.")
          logout()
          return false
        })
    }
  }

  const deletePriceFromTrip = async (priceId: string, tripId: string): Promise<boolean> => {
    return authenticate(token).then(() => {
      // Authenticated
      return api.deletePriceFromTrip(priceId, tripId, token)
        .then((data) => {
          if (!data) throw Error()

          toast.success("Preis wurde erfolgreich gelöscht.")
          return true
        })
        .catch(() => {
          toast.error("Preis konnte nicht gelöscht werden")
          return false
        })
    }).catch(() => {
      toast.error("Ihre Session ist abgelaufen! Bitte loggen Sie sich erneut ein.")
      logout()
      return false
    })
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    /* Set images list based on file input */
    setImages(event.target.files ? event.target.files : new FileList())
  }

  const onDetailsEditorStateChange = (editorState: EditorState) => {
    dispatch({ type: ACTIONS.SET_DETAILS_EDITOR_STATE, payload: { editorState: editorState } })
    dispatch({ type: ACTIONS.SET_TRIP, payload: { trip: { ...state.trip, detail: JSON.stringify(convertToRaw(editorState.getCurrentContent())) } } })
  }

  const onServicesEditorStateChange = (editorState: EditorState) => {
    dispatch({ type: ACTIONS.SET_SERVICES_EDITOR_STATE, payload: { editorState: editorState } })
    dispatch({ type: ACTIONS.SET_TRIP, payload: { trip: { ...state.trip, services: JSON.stringify(convertToRaw(editorState.getCurrentContent())) } } })
  }

  const handlePriceCatChange = (e: any) => {
    setPriceCat({ ...priceCat, category: e.target.value })
  }

  return (
    state.trip ?
      <div>
        <h2 className='text-2xl'>{!state.isNewTrip ? "Reise bearbeiten" : "Neue Reise erstellen"}</h2>
        <div className="w-full mt-4">
          <form onSubmit={form.handleSubmit(onSubmit)} className="bg-white md:shadow-md rounded md:px-8 pt-6 pb-8 mb-4">
            <div className="form-group mb-4">
              <InputField fieldName="title" label="Titel" value={state.trip.title} isRequired={true}
                form={form}
                handleFieldChange={handleFieldChange}
                message="Bitte geben Sie einen Titel ein"
              />
            </div>

            <div className="form-group mb-4">
              <InputField fieldName="description" label="Kurzbeschrieb" value={state.trip.description} isRequired={false}
                form={form}
                handleFieldChange={handleFieldChange}
                message="Bitte geben Sie einen Kurzbeschrieb ein" />
            </div>

            <div className='flex'>
              <div className='form-group basis-1/2 mb-4 pr-4'>
                <label htmlFor="date_begin" className={Style.Label}>Beginn</label>
                <Controller
                  control={form.control}
                  name="date_begin"
                  render={(field) =>
                    <DatePicker
                      {...field}
                      selected={state.trip.date_begin}
                      onChange={(date: Date) => handleDateBeginChange(date)}
                      locale="de"
                      dateFormat="d. MMMM y"
                      className={Style.Input}
                      placeholderText="Bitte geben Sie ein Datum ein"
                    />
                  }
                />

                {form.formState.errors.date_begin && <span className='text-sm text-red-500'>{form.formState.errors.date_begin.message?.toString()}</span>}
              </div>
              <div className="form-group basis-1/2 mb-4 grow">
                <label htmlFor="date_end" className={Style.Label}>Ende</label>
                <Controller
                  control={form.control}
                  name="date_end"
                  render={(field) =>
                    <DatePicker {...field}
                      selected={state.trip.date_end}
                      onChange={(date: Date) => handleDateEndChange(date)}
                      locale="de"
                      dateFormat="d. MMMM y"
                      className={Style.Input}
                      placeholderText="Bitte geben Sie ein Datum ein"
                    />
                  }
                />

                {form.formState.errors.date_end && <span className='text-sm text-red-500'>{form.formState.errors.date_end.message?.toString()}</span>}
              </div>
            </div>

            <div className="form-group mb-4">
              <label htmlFor="tags" className={Style.Label}>Tags</label>
              <TagsInput
                value={state.selectedTags}
                onChange={(tags) => dispatch({ type: ACTIONS.SET_SELECTED_TAGS, payload: { tags: tags } })}
                name="tags"
                separators={["Enter", "Tab", ","]}
                placeHolder="Bitte geben Sie einen oder mehrere passende Tags zur Reise ein"
              />
              <em>z.B. Ferienreise, Tagesfahrt, Velo, ... (Separieren mit Komma)</em>
            </div>


            <div className="form-group mb-4">
              <label htmlFor="detail" className={Style.Label}>Details</label>
              <AnkEditor editorState={state.detailsEditorState} onEditorStateChange={onDetailsEditorStateChange} />
            </div>

            <div className="form-group mb-4">
              <label htmlFor="services" className={Style.Label}>Unsere Leistungen</label>
              <AnkEditor editorState={state.servicesEditorState} onEditorStateChange={onServicesEditorStateChange} />
            </div>


            <div id="price_categories" className='bg-slate-300 rounded-md p-4 mb-4'>
              <h2 className={Style.Title2}>Preis</h2>
              {
                state.trip.prices && state.trip.prices.length > 0 ?
                  state.trip.prices.map((price_category, i) => {
                    return (
                      <div className='flex items-center' key={i}>
                        <div className='p-4'>• {price_category.category}: {formatPrice(price_category.price)}</div>
                        <button type="button" id="remove_price" onClick={handleRemovePrice} data-idx={i}>
                          <svg style={{ color: "#FF0000" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0,0,16,16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" fill="red"></path>
                          </svg>
                        </button>
                      </div>
                    )
                  }) : <p className='italic pb-4'>Bitte fügen Sie Preis und evt. Zuschläge hinzu!</p>
              }

              <div className='flex'>
                <div className="basis-1/2 mr-4 form-group mb-4">
                  <label htmlFor="price_category">Preis/Zuschläge</label>
                  <input type="text"
                    className={Style.Input}
                    id="price_category"
                    value={priceCat.category}
                    {...form.register("price_category", {
                      onChange: handlePriceCatChange
                    })}
                  />
                  {form.formState.errors.price_category && <span className='text-sm text-red-500'>{form.formState.errors.price_category.message?.toString()}</span>}
                </div>
                <div className="basis-1/2 form-group mb-4">
                  <label htmlFor="price">Preis (CHF)</label>
                  {
                    <CurrencyInput
                      id="price"
                      className={Style.Input}
                      placeholder="Bitte geben Sie einen Preis ein"
                      defaultValue={0.00}
                      decimalScale={2}
                      prefix={"CHF "}
                      value={priceCat.price}
                      onValueChange={(value) => { setPriceCat({ ...priceCat, price: value ?? "" }) }}
                    />
                  }
                </div>
              </div>
              <button type="button" className={Style.Button} id="add_price" onClick={handleAddPrice}>Preis hinzufügen</button>
            </div>

            <div className="form-group mb-4">
              <h2>Bilder</h2>
              <div className="flex flex-wrap">
                {
                  // Display images belonging to trip
                  state.trip.images && state.trip.images.length > 0 ?
                    state.trip.images.map((image) => {
                      return (
                        <TripImage key={image.id} image={image} handleSetAsTitle={handleSetAsTitle} handleDeleteImage={handleDeleteImage} isTitle={image.is_main} />
                      )
                    })
                    : <p className='basis-full italic pb-4'>Noch keine Bilder gespeichert!</p>
                }
                {
                  state.imagesUploading.map((image) => {
                    return (
                      <TripImage key={image.id} image={image} isLoading={image.isLoading} handleDeleteImage={handleDeleteImage} />
                    )
                  })
                }
              </div>
              <label htmlFor="images" className={Style.Label}>Bilder</label>
              <input type="file"
                className={Style.Input}
                id="images"
                name="images"
                accept="image/*"
                onChange={handleImageChange}
                multiple />
            </div>

            {
              // Oct. 29, 2023
              // Separate button to upload images
              !state.isNewTrip && tripId ?
                <div className="flex flex-row flex-wrap">
                  <Button
                    message={"Speichern & zurück"}
                    loading={showLoader}
                    disabled={showLoader}
                    type="submit"
                    className="mr-2"
                  />

                  {
                    // Button to upload images
                    <Button
                      message="Bilder hochladen"
                      id="add_images"
                      onClick={handleUploadImages}
                      disabled={images !== undefined ? imagesList.length === 0 || showUploadLoader : true}
                      loading={showUploadLoader}
                    />
                  }
                </div>
                :
                <div>
                  <Button
                    message={"Reise erstellen"}
                    loading={showLoader}
                    disabled={showLoader}
                    type="submit"
                  />
                </div>


            }



            {
              state.imagesUploading && state.imagesUploading.length > 0 &&
              <span>Bilder werden hochgeladen: {state.imagesUploading.length} {state.imagesUploading.length > 1 ? "Bilder" : "Bild"}</span>
            }
          </form>


        </div>
      </div> : <div>Lädt...</div>
  )
}

export default NewTrip
