import { Link } from "react-router-dom"

type Props = {
  setShowContactForm: React.Dispatch<React.SetStateAction<boolean>>,
}

const CatalogueElement = (props: { title: string, link: string, img: string }) => (
  <div className="flex flex-col flex-wrap m-4 justify-center">
    <h2 className="text-center p-4 text-sm mt-4">{props.title}</h2>

    <Link to={props.link}
      className="hover:brightness-125 transition hover:scale-[1.05] w-auto h-48 bg-center bg-contain bg-no-repeat"
      target="_blank"
      style={{ backgroundImage: `url(${props.img})` }}
    >
    </Link>
  </div>
)

const hasPassed = (date: string) => {
  const now = new Date()
  const dateObj = new Date(date)
  return dateObj < now
}

type OpeningHoursElementProps = {
  day: string,
  time: string,
}

const OpeningHoursElement = (props: OpeningHoursElementProps) => {
  return (
    <li className="flex justify-between">
      <div>{props.day}</div>
      <div>{props.time}</div>
    </li>
  )
}

const Footer = ({ setShowContactForm }: Props) => {

  const handleSetShowContactForm = (set: boolean) => {
    setShowContactForm(set)
  }

  return (
    <div className="text-white text-lg bg-gray-900">
      <div className="">
        <div className="flex flex-wrap flex-col sm:flex-row container justify-center text-center sm:text-left sm:m-auto sm:justify-around">
          <div className="p-8 basis-1/3">
            <h2 className="text-2xl font-bold">Kontakt</h2>
            <address className="flex flex-col py-2 not-italic whitespace-nowrap">
              <div>
                A.N.K. Tours AG <br />
                Gerberstrasse 9 <br />
                4410 Liestal
              </div>
              <div className="mt-4">
                <a href="tel:+41619512424">
                  <svg className="h-4 w-4 text-white inline-block mr-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>
                  +41 (0) 61 951 24 24
                </a>
              </div>
              <div>
                <a href="mailto:info@ank-tours.ch">
                  <svg className="h-4 w-4 text-white inline-block mr-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>
                  info@ank-tours.ch
                </a>
              </div>

            </address>
            {
              /* Opening hours */

              <div className="flex flex-col">
                <strong className="mt-4 mb-2 font-gili text-slate-500 text-2xl">Öffnungszeiten Büro</strong>
                <ul>
                  <OpeningHoursElement day="Montag" time="13:30-16:30" />
                  <OpeningHoursElement day="Dienstag" time="08:30-11:30" />
                  <OpeningHoursElement day="Mittwoch" time="08:30-11:30" />
                  <OpeningHoursElement day="Donnerstag" time="13:30-16:30" />
                  <OpeningHoursElement day="Freitag" time="08:30-11:30" />
                </ul>
              </div>
            }


          </div>
          <div className="p-8 basis-1/3 flex flex-col flex-wrap">
            <div className="mb-4">
              <button onClick={() => handleSetShowContactForm(true)} className="mt-4 underline hover:no-underline"
                type="button">
                Kontaktformular
              </button>
            </div>
            <div className="mb-4">
              <Link to="/agb" className="underline hover:no-underline">
                Allgemeine Geschäftsbedingungen
              </Link>
            </div>
            <div className="mb-4">
              <Link to="/jobs" className="underline hover:no-underline">
                Jobs
              </Link>
            </div>
            <div className="mb-4">
              <Link to="/privacy" className="underline hover:no-underline">
                Datenschutzbestimmungen
              </Link>
            </div>

            {
              /* Social Media */
              <div className="mt-auto">
                <a href="https://www.facebook.com/ANK-Tours-110451559043449/" className="m-auto inline-block">
                  <svg className="h-8 w-8 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" /></svg>
                </a>
              </div>
            }
          </div>

          <div className="flex flex-row flex-wrap justify-center mb-8">
          <CatalogueElement
                  title="Reisekatalog 2025"
                  img={process.env.PUBLIC_URL + '/katalog-2025-thumb.png'}
                  link={process.env.PUBLIC_URL + '/Katalog_2025_Final_min.pdf'}
                />
            {
              hasPassed("2025-01-01") ? null :
                <CatalogueElement
                  title="Reisekatalog 2024"
                  img={process.env.PUBLIC_URL + '/katalog2024-thumb.jpg'}
                  link={process.env.PUBLIC_URL + '/Katalog_2024_V5.pdf'}
                />
            }
          </div>
        </div>
      </div>
      <div className="bottomline text-center text-sm pb-32 md:pb-6 pt-12 px-2">
        <span className='text-slate-500 pt-2'>
          2023 ~ <a href="https://github.com/teambebop6">teambebop6</a>
          , <a className='underline hover:no-underline' href="/admin/dashboard">admin</a>
        </span>
      </div>
    </div>
  )
}

export default Footer